.search-feed {
  /* display: flex; */
  flex-direction: column;
  width: 100%;
  height: 90vh;
  /* overflow-y: auto; */
  flex: 1;
  /* background-color: white; */
  align-items: stretch;
  justify-content: flex-start;
  font-size: 93%;
  align-self: center;

  /* font-size: 90%; */
}
/* being used as an indicator of simplicity, of pinnd htoughts feed */
.search-feed.noMargins {
  height: fit-content;
  /* width: 100%; */
  margin-top: .5em;
}
.search-feed.noMargins > * {
  margin: 0.5em 0;
}
/* .search-feed.noMargins > * > * {
  border-top: none;
} */
.toggle {
  cursor: pointer;
}
.simple-button.toggle {
  background-color: transparent;
}
.toggles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.8em;
}
.toggle:active {
  color: black;
}
.toggles > * + * {
  margin-left: 1em;
}
.toggles > * > * {
  border-radius: 0.3em;
}
.selected-option {
  /* background-color: rgb(217, 222, 255); */
  border-radius: 0.3em;
  font-weight: bold;
}
#meVsWe {
  /* color: rgb(240, 240, 240) */
}
.disabled {
  /* opacity: .5; */
}
